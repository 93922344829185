import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { uniq, } from 'lodash';

import SuperAdminRoot from './components/pages/SuperAdminRoot';
import SuperAdminTenants from './components/pages/SuperAdminTenants';
import SuperAdminUsers from './components/pages/SuperAdminUsers';
import AdminRoot from './components/pages/AdminRoot';
import AdminAuditLogs from './components/pages/AdminAuditLogs';
import AdminUsers from './components/pages/AdminUsers';
import AdminUser from './components/pages/AdminUser';
import AdminUserTagRules from './components/pages/AdminUserTagRules';
import AdminArea from './components/pages/AdminArea';
import AdminPlaces from './components/pages/AdminPlaces';
import AdminQuestions from './components/pages/AdminQuestions';
import AdminSurveys from './components/pages/AdminSurveys';
import AdminSurveyAnswers from './components/pages/AdminSurveyAnswers';
import AdminSurveyAnswer from './components/pages/AdminSurveyAnswer';
import AdminSurveyGroups from './components/pages/AdminSurveyGroups';
import AdminUserVoices from './components/pages/AdminUserVoices';
import AdminEvents from './components/pages/AdminEvents';
import AdminEventProductTypes from './components/pages/AdminEventProductTypes';
import AdminLectureTypes from './components/pages/AdminLectureTypes';
import AdminEvent from './components/pages/AdminEvent';
import AdminEventEntries from './components/pages/AdminEventEntries';
import AdminEventEntryFrames from './components/pages/AdminEventEntryFrames';
import AdminInventoriesSchedule from './components/pages/AdminInventoriesSchedule';
import AdminPurchaseOrders from './components/pages/AdminPurchaseOrders';
import AdminContainers from './components/pages/AdminContainers';
import AdminReceivingPlans from './components/pages/AdminReceivingPlans';
import AdminReceivings from './components/pages/AdminReceivings';
import AdminShippings from './components/pages/AdminShippings';
import AdminProductTypes from './components/pages/AdminProductTypes';
import AdminProducts from './components/pages/AdminProducts';
import AdminProductsAgents from './components/pages/AdminProductsAgents';
import AdminContentProductsAgents from './components/pages/AdminContentProductsAgents';
import AdminEnvelopeProducts from './components/pages/AdminEnvelopeProducts';
import AdminProductTags from './components/pages/AdminProductTags';
import AdminCouponTemplates from './components/pages/AdminCouponTemplates';
import AdminCoupons from './components/pages/AdminCoupons';
import AdminOrders from './components/pages/AdminOrders';
import AdminOrder from './components/pages/AdminOrder';
import AdminOrderReceipt from './components/pages/AdminOrderReceipt';
import AdminMultiOrdersUsers from './components/pages/AdminMultiOrdersUsers';
import AdminContentOrders from './components/pages/AdminContentOrders';
import AdminQrUrls from './components/pages/AdminQrUrls';
import AdminAgents from './components/pages/AdminAgents';
import AdminAgentRegister from './components/pages/AdminAgentRegister';
import AdminAgentMembers from './components/pages/AdminAgentMembers';
import AdminAgentQrUrls from './components/pages/AdminAgentQrUrls';
import AdminAgentProducts from './components/pages/AdminAgentProducts';
import AdminAgentContentProducts from './components/pages/AdminAgentContentProducts';
import AdminAgentComments from './components/pages/AdminAgentComments';
import AdminAgentContractPlans from './components/pages/AdminAgentContractPlans';
import AdminAgentSettings from './components/pages/AdminAgentSettings';
import AdminAgentShops from './components/pages/AdminAgentShops';
import AdminAgentShop from './components/pages/AdminAgentShop';
import AdminAgentShopReferrers from './components/pages/AdminAgentShopReferrers';
import AdminAgentShopReferrer from './components/pages/AdminAgentShopReferrer';
import AdminAgentRentalItems from './components/pages/AdminAgentRentalItems';
import AdminContentProducts from './components/pages/AdminContentProducts';
import AdminTroubleInquiries from './components/pages/AdminTroubleInquiries';
import AdminTroubleInquiry from './components/pages/AdminTroubleInquiry';
import AdminInquiries from './components/pages/AdminInquiries';
import AdminInquiry from './components/pages/AdminInquiry';
import AdminMethodInquiries from './components/pages/AdminMethodInquiries';
import AdminMethodInquiry from './components/pages/AdminMethodInquiry';
import AdminInquiryTypes from './components/pages/AdminInquiryTypes';
import AdminMagazines from './components/pages/AdminMagazines';
import AdminMagazine from './components/pages/AdminMagazine';
import AdminMagazineDeliveryHistories from './components/pages/AdminMagazineDeliveryHistories';
import AdminSmsDeliveries from './components/pages/AdminSmsDeliveries';
import AdminSmsDelivery from './components/pages/AdminSmsDelivery';
import AdminSmsDeliveryDeliveryTargets from './components/pages/AdminSmsDeliveryDeliveryTargets';
import AdminEnvelopeSchedules from './components/pages/AdminEnvelopeSchedules';
import AdminEnvelopeSchedule from './components/pages/AdminEnvelopeSchedule';
import AdminEnvelopeScheduleDeliveryHistories from './components/pages/AdminEnvelopeScheduleDeliveryHistories';
import AdminEnvelopeDeliveries from './components/pages/AdminEnvelopeDeliveries';
import AdminEnvelopeDelivery from './components/pages/AdminEnvelopeDelivery';
import AdminEnvelopeDeliveryDeliveryTargets from './components/pages/AdminEnvelopeDeliveryDeliveryTargets';
import AdminInitialTags from './components/pages/AdminInitialTags';
import AdminSettings from './components/pages/AdminSettings';
import AgentNewAgent from './components/pages/AgentNewAgent';
import AgentShops from './components/pages/AgentShops';
import AgentShop from './components/pages/AgentShop';
import AgentShopReferrers from './components/pages/AgentShopReferrers';
import AgentShopReferrer from './components/pages/AgentShopReferrer';
import AgentRentalItems from './components/pages/AgentRentalItems';
import AgentShopRentalPrices from './components/pages/AgentShopRentalPrices';
import AgentShopRentalItemEntities from './components/pages/AgentShopRentalItemEntities';
import AgentRentalOrders from './components/pages/AgentRentalOrders';
import AgentShopRentalCalendar from './components/pages/AgentShopRentalCalendar';
import AgentReferralOrders from './components/pages/AgentReferralOrders';
import AgentTmpOrders from './components/pages/AgentTmpOrders';
import AgentWholesaleOrders from './components/pages/AgentWholesaleOrders';
import AgentMembers from './components/pages/AgentMembers';
import EventQrcode from './components/pages/EventQrcode';
import AdminLecture from './components/pages/AdminLecture';
import AdminEntries from './components/pages/AdminEntries';
import AdminEntry from './components/pages/AdminEntry';
import AdminLectureForm from './components/pages/AdminLectureForm';
import AdminCartSettings from './components/pages/AdminCartSettings';
import AdminEmailSettings from './components/pages/AdminEmailSettings';
import AdminTextSettings from './components/pages/AdminTextSettings';
import AdminEventCalendar from './components/pages/AdminEventCalendar';
import AdminContents from './components/pages/AdminContents';
import AdminShortUrls from './components/pages/AdminShortUrls';
import AdminLocales from './components/pages/AdminLocales';
import Login from './components/pages/Login';
import Events from './components/pages/Events';
import Event from './components/pages/Event';
import EventDetail from './components/pages/EventDetail';
import Lecture from './components/pages/Lecture';
import TmpEntry from './components/pages/TmpEntry';
import Entry from './components/pages/Entry';
import EntryLaterQuestion from './components/pages/EntryLaterQuestion';
import NewOrder from './components/pages/NewOrder';
import Products from './components/pages/Products';
import RentalItems from './components/pages/RentalItems';
import NewRentalOrder from './components/pages/NewRentalOrder';
import Mypage from './components/pages/Mypage';
import MypageEntries from './components/pages/MypageEntries';
import MypageOrders from './components/pages/MypageOrders';
import MypageOrderReceipt from './components/pages/MypageOrderReceipt';
import MypageOrderWarranty from './components/pages/MypageOrderWarranty';
import MypageTroubleInquiries from './components/pages/MypageTroubleInquiries';
import MypageTroubleInquiry from './components/pages/MypageTroubleInquiry';
import MypageInquiries from './components/pages/MypageInquiries';
import MypageMethodInquiries from './components/pages/MypageMethodInquiries';
import MypageProfile from './components/pages/MypageProfile';
import MypageSurveyAnswers from './components/pages/MypageSurveyAnswers';
import MypageRentalOrder from './components/pages/MypageRentalOrder';
import MypageContentOrders from './components/pages/MypageContentOrders';
import EmailVerification from './components/pages/EmailVerification';
import Terms from './components/pages/Terms';
import ReferrerQrCode from './components/pages/ReferrerQrCode';
import TroubleInquiry from './components/pages/TroubleInquiry';
import MethodInquiry from './components/pages/MethodInquiry';
import OrderContract from './components/pages/OrderContract';
import ReferralRedirect from './components/pages/ReferralRedirect';
import OrderThanks from './components/pages/OrderThanks';
import Parts from './components/pages/Parts';
import NewInquiry from './components/pages/NewInquiry';
import InquiryThanks from './components/pages/InquiryThanks';
import Contacts from './components/pages/Contacts';
import Content from './components/pages/Content';
import ContentProduct from './components/pages/ContentProduct';
import NewSurveyAnswer from './components/pages/NewSurveyAnswer';
import SurveyAnswerThanks from './components/pages/SurveyAnswerThanks';
import UserVoice from './components/pages/UserVoice';
import ShortUrl from './components/pages/ShortUrl';
import CheckIn from './components/pages/CheckIn';
import CheckInDirect from './components/pages/CheckInDirect';
import SurveyWithCheckInCode from './components/pages/SurveyWithCheckInCode';
import AdminUserSurveyAnswers from './components/pages/AdminUserSurveyAnswers';
import PublicTroubleInquiry from './components/pages/PublicTroubleInquiry';
import AdminExportRequests from './components/pages/AdminExportRequests';
import AdminContacts from './components/pages/AdminContacts';
import AdminContact from './components/pages/AdminContact';
import AdminContactContactTargets from './components/pages/AdminContactContactTargets';
import AdminUserAccessLogs from './components/pages/AdminUserAccessLogs';
import AdminAccessLogs from './components/pages/AdminAccessLogs';
import AdminPaceMakerSettings from './components/pages/AdminPaceMakerSettings';
import AdminEventCancellationNotices from './components/pages/AdminEventCancellationNotices';
import AdminCustomerJourneys from './components/pages/AdminCustomerJourneys';
import AdminCustomerJourney from './components/pages/AdminCustomerJourney';
import AdminCustomerJourneyHistories from './components/pages/AdminCustomerJourneyHistories';
import AdminMypage from './components/pages/AdminMypage';
import WishOrderPayment from './components/pages/WishOrderPayment';
// PLOP: import

const oldPaths = ['/admin', '/agents', '/login', '/events', '/orders', '/products', '/terms', '/referrerQrCode', '/troubleInquiry', '/troubleInquiries', '/orderContract', '/referralRedirect', '/orderThanks', '/parts', '/inquiries', '/contacts', '/contents', '/surveys'];
const routeObjs = [
  {
    exact: true,
    path: '/superAdmin',
    title: 'SUPER ADMIN',
    component: SuperAdminRoot,
  },
  {
    exact: true,
    path: '/superAdmin/tenants',
    title: 'テナント管理 SUPER ADMIN',
    component: SuperAdminTenants,
  },
  {
    exact: true,
    path: '/superAdmin/users',
    title: 'アカウント管理 SUPER ADMIN',
    component: SuperAdminUsers,
  },
  {
    exact: true,
    path: '/mypage',
    title: 'マイページ',
    component: Mypage,
  },
  {
    exact: true,
    path: '/mypage/entries',
    title: 'マイページ イベント申込み履歴',
    component: MypageEntries,
  },
  {
    exact: true,
    path: '/mypage/orders',
    title: 'マイページ 注文履歴',
    component: MypageOrders,
  },
  {
    exact: true,
    path: '/mypage/orders/:orderId/receipt',
    title: 'マイページ 領収書',
    component: MypageOrderReceipt,
  },
  {
    exact: true,
    path: '/mypage/orders/:orderId/warranty',
    title: 'マイページ 保証書',
    component: MypageOrderWarranty,
  },
  {
    exact: true,
    path: '/mypage/troubleInquiries',
    title: 'マイページ 不具合問合せ履歴',
    component: MypageTroubleInquiries,
  },
  {
    exact: true,
    path: '/mypage/inquiries',
    title: 'マイページ お問合せ履歴',
    component: MypageInquiries,
  },
  {
    exact: true,
    path: '/mypage/methodInquiries',
    title: 'マイページ 乗り方・使い方お問合せ履歴',
    component: MypageMethodInquiries,
  },
  {
    exact: true,
    path: '/mypage/profile',
    title: 'マイページ プロフィール',
    component: MypageProfile,
  },
  {
    exact: true,
    path: '/mypage/surveyAnswers',
    title: 'マイページ アンケート回答履歴',
    component: MypageSurveyAnswers,
  },
  {
    exact: true,
    path: '/mypage/:tenantId/agents/:agentId/rentalOrders/:rentalOrderId',
    title: 'マイページ レンタル注文',
    component: MypageRentalOrder,
  },
  {
    exact: true,
    path: '/mypage/contentOrders',
    title: 'マイページ コンテンツ注文',
    component: MypageContentOrders,
  },
  {
    exact: true,
    path: '/s/:shortUrlKey',
    component: ShortUrl,
  },
  {
    exact: true,
    path: '/emailVerification',
    component: EmailVerification,
  },
  ...oldPaths.map((oldPath) => ({
    path: oldPath,
    component: (_) => <Redirect to={'/vi' + _.location.pathname + _.location.search} />,
  })),
  ...[
    {
      exact: true,
      path: '/',
      component: (_) => <Redirect to={'/' + _.match.params.tenantPath + '/events'} />,
    },
    {
      exact: true,
      path: '/admin',
      title: '管理画面 ADMIN',
      component: AdminRoot,
    },
    {
      exact: true,
      path: '/admin/auditLogs',
      title: '操作ログ ADMIN',
      component: AdminAuditLogs,
    },
    {
      exact: true,
      path: '/admin/users',
      title: 'アカウント管理 ADMIN',
      component: AdminUsers,
    },
    {
      exact: true,
      path: '/admin/users/:userId',
      title: 'アカウント詳細 ADMIN',
      component: AdminUser,
    },
    {
      exact: true,
      path: '/admin/users/:userId/surveyAnswers',
      title: 'アンケート回答一覧 ADMIN',
      component: AdminUserSurveyAnswers,
    },
    {
      exact: true,
      path: '/admin/users/:userId/accessLogs',
      title: 'アクセスログ ADMIN',
      component: AdminUserAccessLogs,
    },
    {
      exact: true,
      path: '/admin/userTagRules',
      title: 'ユーザータグルール一覧 ADMIN',
      component: AdminUserTagRules,
    },
    {
      exact: true,
      path: '/admin/area',
      title: 'エリア管理 ADMIN',
      component: AdminArea,
    },
    {
      exact: true,
      path: '/admin/places',
      title: '開催場所一覧 ADMIN',
      component: AdminPlaces,
    },
    {
      exact: true,
      path: '/admin/questions',
      title: 'アンケート一覧 ADMIN',
      component: AdminQuestions,
    },
    {
      exact: true,
      path: '/admin/surveys',
      title: 'アンケートページ一覧 ADMIN',
      component: AdminSurveys,
    },
    {
      exact: true,
      path: '/admin/surveyAnswers',
      title: 'アンケート回答一覧 ADMIN',
      component: AdminSurveyAnswers,
    },
    {
      exact: true,
      path: '/admin/surveyAnswers/:surveyAnswerId',
      title: 'アンケートページ回答詳細 ADMIN',
      component: AdminSurveyAnswer,
    },
    {
      exact: true,
      path: '/admin/surveyGroups',
      title: 'アンケートグループ管理 ADMIN',
      component: AdminSurveyGroups,
    },
    {
      exact: true,
      path: '/admin/userVoices',
      title: 'ADMIN',
      component: AdminUserVoices,
    },
    {
      exact: true,
      path: '/admin/events',
      title: 'イベント一覧 ADMIN',
      component: AdminEvents,
    },
    {
      exact: true,
      path: '/admin/eventProductTypes',
      title: 'イベント商品種別一覧 ADMIN',
      component: AdminEventProductTypes,
    },
    {
      exact: true,
      path: '/admin/lectureTypes',
      title: 'レクチャー種別一覧 ADMIN',
      component: AdminLectureTypes,
    },
    {
      exact: true,
      path: '/admin/events/:eventId',
      title: 'イベント詳細 ADMIN',
      component: AdminEvent,
    },
    {
      exact: true,
      path: '/admin/events/:eventId/entries',
      title: '申込み一覧 ADMIN',
      component: AdminEventEntries,
    },
    {
      exact: true,
      path: '/admin/events/:eventId/entryFrames',
      title: '参加者一覧 ADMIN',
      component: AdminEventEntryFrames,
    },
    {
      exact: true,
      path: '/admin/events/:eventId/lectures/new',
      title: '新規レクチャー ADMIN',
      component: AdminLectureForm,
    },
    {
      exact: true,
      path: '/admin/events/:eventId/lectures/:lectureId',
      title: 'レクチャー詳細 ADMIN',
      component: AdminLecture,
    },
    {
      exact: true,
      path: '/admin/events/:eventId/cancellationNotices',
      title: '空き待ち一覧 ADMIN',
      component: AdminEventCancellationNotices,
    },
    {
      exact: true,
      path: '/admin/entries',
      title: 'イベント申込み一覧 ADMIN',
      component: AdminEntries,
    },
    {
      exact: true,
      path: '/admin/events/:eventId/entries/:entryId',
      title: '申込み詳細 ADMIN',
      component: AdminEntry,
    },
    {
      exact: true,
      path: '/admin/events/:eventId/lectures/:lectureId/edit',
      title: 'レクチャー編集 ADMIN',
      component: AdminLectureForm,
    },
    {
      exact: true,
      path: '/admin/eventCalendar',
      title: 'イベントカレンダー ADMIN',
      component: AdminEventCalendar,
    },
    {
      exact: true,
      path: '/admin/cartSettings',
      title: 'カート設定 ADMIN',
      component: AdminCartSettings,
    },
    {
      exact: true,
      path: '/admin/emailSettings',
      title: 'メール設定 ADMIN',
      component: AdminEmailSettings,
    },
    {
      exact: true,
      path: '/admin/textSettings',
      title: 'テキスト設定 ADMIN',
      component: AdminTextSettings,
    },
    {
      exact: true,
      path: '/admin/inventories',
      title: 'ADMIN',
      component: (_) => <Redirect to={`/${_.match.params.tenantPath}/admin/inventories/schedule`} />,
    },
    {
      exact: true,
      path: '/admin/inventories/schedule',
      title: 'ADMIN',
      component: AdminInventoriesSchedule,
    },
    {
      exact: true,
      path: '/admin/inventories/purchaseOrders',
      title: 'ADMIN',
      component: AdminPurchaseOrders,
    },
    {
      exact: true,
      path: '/admin/inventories/containers',
      title: 'ADMIN',
      component: AdminContainers,
    },
    {
      exact: true,
      path: '/admin/receivingPlans',
      title: '入荷予定管理 ADMIN',
      component: AdminReceivingPlans,
    },
    {
      exact: true,
      path: '/admin/receivings',
      title: '入荷管理 ADMIN',
      component: AdminReceivings,
    },
    {
      exact: true,
      path: '/admin/shippings',
      title: '出荷管理 ADMIN',
      component: AdminShippings,
    },
    {
      exact: true,
      path: '/admin/productTypes',
      title: '商品種別一覧 ADMIN',
      component: AdminProductTypes,
    },
    {
      exact: true,
      path: '/admin/products',
      title: '商品一覧 ADMIN',
      component: AdminProducts,
    },
    {
      exact: true,
      path: '/admin/productsAgents',
      title: '商品 × 代理店管理 ADMIN',
      component: AdminProductsAgents,
    },
    {
      exact: true,
      path: '/admin/contentProductsAgents',
      title: 'コンテンツ商品 × 代理店管理 ADMIN',
      component: AdminContentProductsAgents,
    },
    {
      exact: true,
      path: '/admin/envelopeProducts',
      title: '郵送物一覧 ADMIN',
      component: AdminEnvelopeProducts,
    },
    {
      exact: true,
      path: '/admin/couponTemplates',
      title: '優待テンプレート一覧 ADMIN',
      component: AdminCouponTemplates,
    },
    {
      exact: true,
      path: '/admin/coupons',
      title: '優待一覧 ADMIN',
      component: AdminCoupons,
    },
    {
      exact: true,
      path: '/admin/productTags',
      title: '商品タグ一覧 ADMIN',
      component: AdminProductTags,
    },
    {
      exact: true,
      path: '/admin/orders',
      title: '注文一覧 ADMIN',
      component: AdminOrders,
    },
    {
      exact: true,
      path: '/admin/orders/:orderId',
      title: '注文詳細 ADMIN',
      component: AdminOrder,
    },
    {
      exact: true,
      path: '/admin/orders/:orderId/receipt',
      title: '領収書 ADMIN',
      component: AdminOrderReceipt,
    },
    {
      exact: true,
      path: '/admin/multiOrdersUsers',
      title: '複数注文アカウント管理 ADMIN',
      component: AdminMultiOrdersUsers,
    },
    {
      exact: true,
      path: '/admin/contentOrders',
      title: 'コンテンツ注文管理 ADMIN',
      component: AdminContentOrders,
    },
    {
      exact: true,
      path: '/admin/qrUrls',
      title: '紹介QRコード遷移先一覧 ADMIN',
      component: AdminQrUrls,
    },
    {
      exact: true,
      path: '/admin/agents',
      title: '代理店一覧 ADMIN',
      component: AdminAgents,
    },
    {
      exact: true,
      path: '/admin/agents/register',
      title: '代理店登録ウィザード ADMIN',
      component: AdminAgentRegister,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/members',
      title: 'ビジネスアカウント ADMIN',
      component: AdminAgentMembers,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/qrUrls',
      title: '紹介QRコード遷移先 ADMIN',
      component: AdminAgentQrUrls,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/products',
      title: '商品 ADMIN',
      component: AdminAgentProducts,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/contentProducts',
      title: 'コンテンツ商品 ADMIN',
      component: AdminAgentContentProducts,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/comments',
      title: 'コメント ADMIN',
      component: AdminAgentComments,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/contractPlans',
      title: '規約 ADMIN',
      component: AdminAgentContractPlans,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/settings',
      title: '設定 ADMIN',
      component: AdminAgentSettings,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/agentShops',
      title: '店舗 ADMIN',
      component: AdminAgentShops,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/agentShops/:agentShopId',
      title: 'ADMIN',
      component: (_) => <Redirect to={`/${_.match.params.tenantPath}/admin/agents/${_.match.params.agentId}/agentShops/${_.match.params.agentShopId}/general`} />,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/agentShops/:agentShopId/general',
      title: '店舗詳細 ADMIN',
      component: AdminAgentShop,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/agentShops/:agentShopId/referrers',
      title: 'リファラ ADMIN',
      component: AdminAgentShopReferrers,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/agentShops/:agentShopId/referrers/:referrerId',
      title: 'リファラ詳細 ADMIN',
      component: AdminAgentShopReferrer,
    },
    {
      exact: true,
      path: '/admin/agents/:agentId/rentalItems',
      title: 'レンタル商品 ADMIN',
      component: AdminAgentRentalItems,
    },
    {
      exact: true,
      path: '/admin/contentProducts',
      title: 'コンテンツ商品一覧 ADMIN',
      component: AdminContentProducts,
    },
    {
      exact: true,
      path: '/admin/troubleInquiries',
      title: '不具合組立問合せ一覧 ADMIN',
      component: AdminTroubleInquiries,
    },
    {
      exact: true,
      path: '/admin/troubleInquiries/:troubleInquiryId',
      title: '不具合組立問合せ詳細 ADMIN',
      component: AdminTroubleInquiry,
    },
    {
      exact: true,
      path: '/admin/inquiries',
      title: 'お問合せ一覧 ADMIN',
      component: AdminInquiries,
    },
    {
      exact: true,
      path: '/admin/inquiries/:inquiryId',
      title: 'お問合せ詳細 ADMIN',
      component: AdminInquiry,
    },
    {
      exact: true,
      path: '/admin/methodInquiries',
      title: '乗り方・使い方問合せ一覧 ADMIN',
      component: AdminMethodInquiries,
    },
    {
      exact: true,
      path: '/admin/methodInquiries/:methodInquiryId',
      title: '乗り方・使い方問合せ詳細 ADMIN',
      component: AdminMethodInquiry,
    },
    {
      exact: true,
      path: '/admin/inquiryTypes',
      title: '問合せ種別一覧 ADMIN',
      component: AdminInquiryTypes,
    },
    {
      exact: true,
      path: '/admin/magazines',
      title: 'ステップSMS配信管理 ADMIN',
      component: AdminMagazines,
    },
    {
      exact: true,
      path: '/admin/magazines/:magazineId',
      title: 'ステップSMS配信詳細 ADMIN',
      component: AdminMagazine,
    },
    {
      exact: true,
      path: '/admin/magazines/:magazineId/deliveryHistories',
      title: 'ステップSMS配信履歴 ADMIN',
      component: AdminMagazineDeliveryHistories,
    },
    {
      exact: true,
      path: '/admin/smsDeliveries',
      title: '一括SMS配信 ADMIN',
      component: AdminSmsDeliveries,
    },
    {
      exact: true,
      path: '/admin/smsDeliveries/:smsDeliveryId',
      title: 'SMS配信詳細 ADMIN',
      component: AdminSmsDelivery,
    },
    {
      exact: true,
      path: '/admin/smsDeliveries/:smsDeliveryId/deliveryTargets',
      title: 'SMS配信履歴 ADMIN',
      component: AdminSmsDeliveryDeliveryTargets,
    },
    {
      exact: true,
      path: '/admin/envelopeSchedules',
      title: '郵便スケジュール管理 ADMIN',
      component: AdminEnvelopeSchedules,
    },
    {
      exact: true,
      path: '/admin/envelopeSchedules/:envelopeScheduleId',
      title: '郵便スケジュール詳細 ADMIN',
      component: AdminEnvelopeSchedule,
    },
    {
      exact: true,
      path: '/admin/customerJourneys',
      title: 'カスタマージャーニー配信 ADMIN',
      component: AdminCustomerJourneys,
    },
    {
      exact: true,
      path: '/admin/customerJourneys/:customerJourneyId',
      title: 'カスタマージャーニー配信詳細 ADMIN',
      component: AdminCustomerJourney,
    },
    {
      exact: true,
      path: '/admin/customerJourneys/:customerJourneyId/deliveryHistories',
      title: 'カスタマージャーニー配信履歴 ADMIN',
      component: AdminCustomerJourneyHistories,
    },
    {
      exact: true,
      path: '/admin/envelopeSchedules/:envelopeScheduleId/deliveryHistories',
      title: '受注作成履歴 ADMIN',
      component: AdminEnvelopeScheduleDeliveryHistories,
    },
    {
      exact: true,
      path: '/admin/envelopeDeliveries',
      title: '一括郵便発送 ADMIN',
      component: AdminEnvelopeDeliveries,
    },
    {
      exact: true,
      path: '/admin/envelopeDeliveries/:envelopeDeliveryId',
      title: '一括郵便発送詳細 ADMIN',
      component: AdminEnvelopeDelivery,
    },
    {
      exact: true,
      path: '/admin/envelopeDeliveries/:envelopeDeliveryId/deliveryTargets',
      title: '一括郵便発送履歴 ADMIN',
      component: AdminEnvelopeDeliveryDeliveryTargets,
    },
    {
      exact: true,
      path: '/admin/contacts',
      title: 'コンタクトリスト一覧 ADMIN',
      component: AdminContacts
    },
    {
      exact: true,
      path: '/admin/contacts/:contactId',
      title: 'コンタクトリスト詳細 ADMIN',
      component: AdminContact,
    },
    {
      exact: true,
      path: '/admin/contacts/:contactId/contactTargets',
      title: 'コンタクトリスト ADMIN',
      component: AdminContactContactTargets,
    },
    {
      exact: true,
      path: '/admin/contents',
      title: 'コンテンツ一覧 ADMIN',
      component: AdminContents,
    },
    {
      exact: true,
      path: '/admin/shortUrls',
      title: '短縮URL一覧 ADMIN',
      component: AdminShortUrls,
    },
    {
      exact: true,
      path: '/admin/initialTags',
      title: '初期化タグ一覧 ADMIN',
      component: AdminInitialTags,
    },
    {
      exact: true,
      path: '/admin/exportRequests',
      title: 'エクスポート履歴 ADMIN',
      component: AdminExportRequests,
    },
    {
      exact: true,
      path: '/admin/settings',
      title: '設定 ADMIN',
      component: AdminSettings,
    },
    {
      exact: true,
      path: '/admin/accessLogs',
      title: 'アクセスログ集計 ADMIN',
      component: AdminAccessLogs,
    },
    {
      exact: true,
      path: '/admin/paceMakerSettings',
      title: 'ペースメーカー設定 ADMIN',
      component: AdminPaceMakerSettings,
    },
    {
      exact: true,
      path: '/admin/locales',
      title: '言語 ADMIN',
      component: AdminLocales,
    },
    {
      exact: true,
      path: '/agents/new',
      component: AgentNewAgent,
    },
    {
      exact: true,
      path: '/agents/:agentId',
      component: (_) => <Redirect to={`/${_.match.params.tenantPath}/agents/${_.match.params.agentId}/shops`} />,
    },
    {
      exact: true,
      path: '/agents/:agentId/shops',
      component: AgentShops,
    },
    {
      exact: true,
      path: '/agents/:agentId/agentShops/:agentShopId',
      component: (_) => <Redirect to={`/${_.match.params.tenantPath}/agents/${_.match.params.agentId}/agentShops/${_.match.params.agentShopId}/general`} />,
    },
    {
      exact: true,
      path: '/agents/:agentId/agentShops/:agentShopId/general',
      component: AgentShop,
    },
    {
      exact: true,
      path: '/agents/:agentId/agentShops/:agentShopId/referrers',
      component: AgentShopReferrers,
    },
    {
      exact: true,
      path: '/agents/:agentId/agentShops/:agentShopId/referrers/:referrerId',
      component: AgentShopReferrer,
    },
    {
      exact: true,
      path: '/agents/:agentId/rentalItems',
      component: AgentRentalItems,
    },
    {
      exact: true,
      path: '/agents/:agentId/rentalOrders',
      component: AgentRentalOrders,
    },
    {
      exact: true,
      path: '/agents/:agentId/agentShops/:agentShopId/rentalItemEntities',
      component: AgentShopRentalItemEntities,
    },
    {
      exact: true,
      path: '/agents/:agentId/agentShops/:agentShopId/rentalPrices',
      component: AgentShopRentalPrices,
    },
    {
      exact: true,
      path: '/agents/:agentId/agentShops/:agentShopId/rentalCalendar',
      component: AgentShopRentalCalendar,
    },
    {
      exact: true,
      path: '/agents/:agentId/referralOrders',
      component: AgentReferralOrders,
    },
    {
      exact: true,
      path: '/agents/:agentId/tmpOrders',
      component: AgentTmpOrders,
    },
    {
      exact: true,
      path: '/agents/:agentId/wholesaleOrders',
      component: AgentWholesaleOrders,
    },
    {
      exact: true,
      path: '/agents/:agentId/members',
      component: AgentMembers,
    },
    {
      exact: true,
      path: '/login',
      title: 'ログイン',
      component: Login,
    },
    {
      exact: true,
      path: '/events',
      title: 'イベント一覧',
      component: Events,
    },
    {
      exact: true,
      path: '/events/:eventId',
      component: Event,
    },
    {
      exact: true,
      path: '/events/:eventId/detail',
      component: EventDetail,
    },
    {
      exact: true,
      path: '/events/:eventId/qrcode',
      component: EventQrcode,
    },
    {
      exact: true,
      path: '/events/:eventId/lectures/:lectureId',
      component: Lecture,
    },
    {
      exact: true,
      path: '/events/:eventId/entries/new',
      component: TmpEntry,
    },
    {
      exact: true,
      path: '/events/:eventId/entries/:entryId',
      component: Entry,
    },
    {
      exact: true,
      path: '/events/:eventId/entries/:entryId/frames/:frameIndex/laterQuestions/new',
      component: EntryLaterQuestion,
    },
    {
      exact: true,
      path: '/orders/new',
      title: '注文情報の入力',
      component: NewOrder,
    },
    {
      exact: true,
      path: '/products',
      title: '商品一覧',
      component: Products,
    },
    {
      exact: true,
      path: '/rentalItems',
      title: 'レンタル商品一覧',
      component: RentalItems,
    },
    {
      exact: true,
      path: '/rentalOrders/new',
      title: 'レンタル注文情報の入力',
      component: NewRentalOrder,
    },
    // NOTE: 不要になったので一旦コメントアウト
    // {
    //   exact: true,
    //   path: '/mypage/troubleInquiries/:troubleInquiryId',
    //   component: MypageTroubleInquiry,
    // },
    {
      exact: true,
      path: '/terms',
      component: Terms,
    },
    {
      exact: true,
      path: '/referrerQrCode',
      component: ReferrerQrCode,
    },
    {
      exact: true,
      path: '/troubleInquiry',
      title: '不具合問合せ',
      component: TroubleInquiry,
    },
    {
      path: '/troubleInquiries/new',
      title: '不具合問合せ',
      component: PublicTroubleInquiry,
    },
    {
      path: '/methodInquiry',
      title: '乗り方・使い方サポート',
      component: MethodInquiry,
    },
    {
      exact: true,
      path: '/orderContract',
      component: OrderContract,
    },
    {
      exact: true,
      path: '/referralRedirect',
      component: ReferralRedirect,
    },
    {
      exact: true,
      path: '/orderThanks',
      title: '注文完了',
      component: OrderThanks,
    },
    {
      exact: true,
      path: '/parts',
      title: 'パーツ購入',
      component: Parts,
    },
    {
      exact: true,
      path: '/inquiries/new',
      title: '問合せ',
      component: NewInquiry,
    },
    {
      exact: true,
      path: '/inquiries/thanks',
      title: '問合せ完了',
      component: InquiryThanks,
    },
    {
      exact: true,
      path: '/contacts',
      component: Contacts,
    },
    {
      exact: true,
      path: '/contents/:contentId',
      component: Content,
    },
    {
      exact: true,
      path: '/contentProducts/:contentProductId',
      component: ContentProduct,
    },
    {
      exact: true,
      path: '/surveys/:surveyId/surveyAnswers/new',
      component: NewSurveyAnswer,
    },
    {
      exact: true,
      path: '/surveys/:surveyId/surveyAnswers/thanks',
      component: SurveyAnswerThanks,
    },
    {
      exact: true,
      path: '/userVoices/:userVoiceId',
      component: UserVoice,
    },
    {
      exact: true,
      path: '/events/:eventId/checkIn',
      component: CheckIn,
      props: {
        showsHeader: false,
      },
    },
    {
      exact: true,
      path: '/events/:eventId/checkInDirect',
      component: CheckInDirect,
    },
    {
      exact: true,
      path: '/events/:eventId/surveyWithCheckInCode',
      component: SurveyWithCheckInCode,
    },
    {
      exact: true,
      path: '/admin/mypage',
      component: AdminMypage,
    },
    {
      exact: true,
      path: '/wishOrders/payment',
      component: WishOrderPayment,
    },
    // PLOP: routeObject
  ].map((_) => ({ ..._, path: '/:tenantPath' + _.path })),
];


export default function routes(extraProps) {
  return (
    <Switch>
      {routeObjs.map((route, i) => {
        const { exact, path, render, props, title: pageTitle = ' ', } = route;
        return (
          <Route
            exact={exact}
            path={path}
            render={render || ((_) => <route.component {..._} {...extraProps} {...{ ...props, pageTitle, }} />)}
            key={i}
          />
        );
      })}
    </Switch>
  );
}
