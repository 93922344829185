const { mapValues, groupBy, sumBy, isEmpty, } = require('lodash');
const { format: formatDate, } = require('date-fns');

module.exports = {
  fields: () => {
    return {
      receivedOn: {
        label: '入荷日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
      },
    };
  },
  itemFields: ({ products, }) => {
    return {
      productId: {
        label: '商品',
        type: 'select',
        options: products.map(_ => ({ label: _.code, value: _.id })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      quantity: {
        label: '数量',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        showsTextInput: true,
      },
    };
  },
};
