import React, { useMemo, Fragment, } from 'react';
import { pick, omit, isEmpty, orderBy, sortBy, keyBy, } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate, startOfDay, endOfDay, } from 'date-fns';
import numeral from 'numeral';

import firebase from '../../firebase';
import { colors, } from '../../shared/config';
import { fieldDisplayValue, } from '../../shared/util';
import { canUpdateShipping } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import ShippingFormModal from '../modals/ShippingFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { fields, } from '../../shared/models/shipping';
import QuerySelector from '../QuerySelector';
import QueryDateRangeSelector from '../QueryDateRangeSelector';

const { keys, entries } = Object;
const db = firebase.firestore();

export default AdminPage(function AdminInventoriesSchedule (props) {
  const { tenant, user } = props;
  const queryParams = useQueryParams();
  const shippedOnStartOn = useMemo(_ => queryParams.shippedOnRange?.[0] ? startOfDay(new Date(queryParams.shippedOnRange[0])) : null, [queryParams.shippedOnRange]);
  const shippedOnEndOn = useMemo(_ => queryParams.shippedOnRange?.[1] ? endOfDay(new Date(queryParams.shippedOnRange[1])) : null, [queryParams.shippedOnRange]);
  const productTypes = useCollectionSubscriptionInTenant(db.collection('productTypes').orderBy('index'));
  const productTypesById = keyBy(productTypes, 'id');
  const productTypeOptions = productTypes.map(_ => ({ value: _.id, label: _.name }));
  const products = useCollectionSubscriptionInTenant(db.collection('products').orderBy('code'));
  const productOptions = products.map(_ => ({ value: _.id, label: _.code }));
  const productsById = keyBy(products, 'id');
  const _shippings = useCollectionSubscriptionInTenant(db.collection('shippings'));
  const shippings = useMemo(_ => orderBy(_shippings, _ => _.shippedOn.toDate()), [_shippings]);

  let filteredRowGroups = shippings;
  if(shippedOnStartOn != null) {
    filteredRowGroups = filteredRowGroups.filter(_ => _.shippedOn.toDate() >= shippedOnStartOn);
  }
  if(shippedOnEndOn != null) {
    filteredRowGroups = filteredRowGroups.filter(_ => _.shippedOn.toDate() <= shippedOnEndOn);
  }
  filteredRowGroups = filteredRowGroups.map((rowGroup) => {
    let filteredItems = rowGroup.items;
    if(!isEmpty(queryParams.productTypeIds)) {
      filteredItems = filteredItems.filter(_ => productsById[_.productId]?.productTypeIds?.some(_ => queryParams.productTypeIds.includes(_)));
    }
    if(!isEmpty(queryParams.productIds)) {
      filteredItems = filteredItems.filter(_ => queryParams.productIds.includes(_.productId));
    }
    return {
      ...rowGroup,
      filteredItems,
    };
  }).filter(_ => _.filteredItems.length > 0);

  return (
    <div>
      <div className="admin-shippings container-fluid py-5 position-relative bg-white">
        <div className="d-flex justify-content-center mb-3">
          <h4>出荷管理</h4>
        </div>
        <div className='mt-2 d-flex align-items-end flex-wrap gap-2'>
          <QueryDateRangeSelector label="出荷日" defaultValue={[shippedOnStartOn, shippedOnEndOn]} paramName="shippedOnRange" pickerProps={{ isClearable: true, showYearDropdown: true, dropdownMode: 'select' }} />
          <QuerySelector paramName="productTypeIds" width={400} isMulti options={productTypeOptions} label="商品種別で絞込み" />
          <QuerySelector paramName="productIds" width={300} isMulti options={productOptions} label="商品で絞込み" />
        </div>
        <div className="mt-4 d-flex justify-content-end mb-3">
          <AddInTenantButton itemRef={db.collection('shippings').doc()} FormModal={ShippingFormModal} formProps={{ products, }} disabled={!canUpdateShipping(user)} />
        </div>
        <div>
          {
            shippings.length > 0 ? (
              <table className="table table-bordered table-sm">
                <thead className="thead-light text-center">
                  <tr>
                    <th style={{ width: 200 }}></th>
                    {
                      entries(pick(fields(), ['shippedOn'])).map(([k, v]) => {
                        return (
                          <th key={k}>
                            {v.label}
                          </th>
                        );
                      })
                    }
                    <th>商品コード</th>
                    <th>数量</th>
                    {
                      entries(omit(fields(), ['shippedOn'])).map(([k, v]) => {
                        return (
                          <th key={k}>
                            {v.label}
                          </th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRowGroups.map((shipping) => {
                      const { id, ref, shippedOn, items, } = shipping;

                      return (
                        <Fragment key={id}>
                          {
                            items.map((item, i) => {
                              const { productId, quantity, } = item;
                              const product = productsById[productId];
                              return (
                                <tr key={id}>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        <td rowSpan={items.length} className="text-nowrap">
                                          <EditButton itemRef={ref} FormModal={ShippingFormModal} formProps={{ products, }} disabled={!canUpdateShipping(user)} />
                                          <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateShipping(user)} />
                                        </td>
                                        {
                                          entries(pick(fields(), ['shippedOn'])).map(([fieldName, fieldSetting]) => {
                                            return (
                                              <td key={fieldName} rowSpan={items.length}>
                                                {fieldDisplayValue(shipping[fieldName], fieldSetting)}
                                              </td>
                                            );
                                          })
                                        }
                                      </Fragment>
                                    )
                                  }
                                  <td>
                                    {product?.code}
                                  </td>
                                  <td className="text-right">
                                    {numeral(quantity).format()}
                                  </td>
                                  {
                                    i === 0 && (
                                      <Fragment>
                                        {
                                          entries(omit(fields(), ['shippedOn'])).map(([fieldName, fieldSetting]) => {
                                            return (
                                              <td key={fieldName} rowSpan={items.length}>
                                                {fieldDisplayValue(shipping[fieldName], fieldSetting)}
                                              </td>
                                            );
                                          })
                                        }
                                      </Fragment>
                                    )
                                  }
                                </tr>
                              );
                            })
                          }
                        </Fragment>
                      );
                    })
                  }
                </tbody>
              </table>
            ) : (
              <div>
                出荷は未登録です
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
