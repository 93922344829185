import React, { Fragment, useMemo, useEffect, } from 'react';
import { set } from 'lodash/fp';
import { Input, } from 'reactstrap';
import { pickBy, uniq, mapValues, groupBy, sumBy, omitBy, isUndefined, isEqual, isEmpty, fromPairs, pick, keyBy, sortBy, } from 'lodash';
import { collection, collectionGroup, query, where, getCountFromServer, } from 'firebase/firestore';
import { useAsync, useList } from 'react-use';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import classnames from 'classnames';
import { format as formatDate, addMonths, eachMonthOfInterval, addYears, startOfMonth, endOfMonth, addDays, } from 'date-fns';
import qs from 'qs';

import firebase, { functions } from '../../firebase';
import { batch, } from '../../shared/firebase';
import { canUpdateProspectPattern, } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import ModelFormModal from '../modals/ModelFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useFirebaseUser from '../hooks/useFirebaseUser';
import QueryDateRangeSelector from '../QueryDateRangeSelector';
import AdminHelp from '../AdminHelp';
import InventoriesHeader from '../InventoriesHeader';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';
import { prospectFields, } from '../../shared/models/product';
import { computeSchedule, } from '../../shared/models/purchaseOrder';
import useQueryParams from '../hooks/useQueryParams';
import QueryBoolean from '../QueryBoolean';
import ModalButton from '../ModalButton';
import TenantLink from '../TenantLink';

const { keys, entries } = Object;
const metrics = {
  currentYearOrderCount: {
    label: '注文数',
  },
  prevYearOrderCount: {
    label: '前年注文数',
  },
  vsPrevYearOrderCount: {
    label: 'VS前年',
    isComparison: _ => true,
    rate: _ => _.vsPrevYearOrderCount() / _.prevYearOrderCount(),
  },
  expectedOrderCount: {
    label: '予想販売数',
  },
  arrivalCount: {
    label: '入荷予定数',
    path: _ => `/admin/inventories/containers?${qs.stringify({ [_.type === 'product' ? 'productIds' : 'productTypeIds']: [_.id], arrivalDateRange: [formatDate(_.month, 'yyyy-MM-dd'), formatDate(endOfMonth(_.month), 'yyyy-MM-dd')], })}`,
  },
  tmpOrderCount: {
    label: '卸仮注文数',
  },
  inventoryCount: {
    label: '月末在庫数',
    className: _ => _.shouldAlertInventory?.() ? 'bg-danger text-white' : '',
  },
  preOrderCount: {
    label: '予約注文数',
  },
  manufactureCount: {
    label: '製造数',
    path: _ => `/admin/inventories/purchaseOrders?${qs.stringify({ [_.type === 'product' ? 'productIds' : 'productTypeIds']: [_.id], scheduledManufactureDateRange: [formatDate(_.month, 'yyyy-MM-dd'), formatDate(endOfMonth(_.month), 'yyyy-MM-dd')], })}`,
  },
  transferingCount: {
    label: '輸送中',
    path: _ => `/admin/inventories/containers?${qs.stringify({
      [_.type === 'product' ? 'productIds' : 'productTypeIds']: [_.id],
      departureDateRange: ['1900-01-01', formatDate(endOfMonth(_.month), 'yyyy-MM-dd')],
      arrivalDateRange: [formatDate(addMonths(_.month, 1), 'yyyy-MM-dd')],
    })}`,
  },
  abroadInventoryCount: {
    label: '海外在庫数',
    className: _ => _.shouldAlertAbroadInventory?.() ? 'bg-danger text-white' : '',
  },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));
const defaultMetricNames = ['currentYearOrderCount', 'expectedOrderCount', 'arrivalCount', 'inventoryCount', 'abroadInventoryCount'];
const db = firebase.firestore();
const saveOrderCounts = functions.httpsCallable('saveOrderCounts');

export default AdminPage(function AdminInventoriesSchedule (props) {
  const { history, location, tenant, user } = props;
  const queryParams = useQueryParams();
  const { firebaseUser } = useFirebaseUser();
  const [selectedIds, { push: selectId, removeAt: removeSelectedIdAt, set: setSelectedId, }] = useList([]);
  const unselectId = _ => removeSelectedIdAt(selectedIds.indexOf(_));
  const start = queryParams.dateRange?.[0] ? startOfMonth(new Date(queryParams.dateRange[0])) : startOfMonth(addMonths(new Date(), -3));
  const end = queryParams.dateRange?.[1] ? endOfMonth(new Date(queryParams.dateRange[1])) : endOfMonth(addMonths(new Date(), 6));
  const bodyProducts = useCollectionSubscriptionInTenant(db.collection('products').where('isBody', '==', true));
  const months = eachMonthOfInterval({ start, end, });
  const prospectPatterns = sortBy(useCollectionSubscriptionInTenant(db.collection('prospectPatterns'), []), _ => _.createdAt.toDate());
  const prospectPatternOptions = prospectPatterns.map(_ => ({ label: _.name, value: _.id }));
  const prospectPatternsById = useMemo(_ => keyBy(prospectPatterns, 'id'), [prospectPatterns]);
  const currentProspectPattern = prospectPatternsById[queryParams.prospectPatternId];
  const cellNotes = useCollectionSubscriptionInTenant(db.collection('cellNotes'));
  const cellNotesById = useMemo(_ => keyBy(cellNotes, 'id'), [cellNotes]);
  const purchaseOrders = useCollectionSubscriptionInTenant(db.collection('purchaseOrders'));
  const containers = useCollectionSubscriptionInTenant(db.collection('containers'));
  const computedPurchaseAndContainerCounts = useMemo(_ => computeSchedule(purchaseOrders, containers), [purchaseOrders, containers]);
  const currentMonthOrders = useCollectionSubscriptionInTenant(db.collection('orders').where('createdAt', '>=', startOfMonth(new Date())));
  const currentMonthOrderItemsByProductId = useMemo(_ => groupBy(currentMonthOrders.flatMap(_ => _.orderItems), 'productId'), [currentMonthOrders]);
  const currentYearOrderCounts = useCollectionSubscriptionInTenant(db.collection('orderCounts').where('month', '>=', start).where('month', '<=', end));
  const prevYearOrderCounts = useCollectionSubscriptionInTenant(db.collection('orderCounts').where('month', '>=', addYears(start, -1)).where('month', '<=', addYears(end, -1)));
  const inventoryCounts = useCollectionSubscriptionInTenant(db.collection('inventoryCounts').where('month', '>=', start));
  const currentYearOrderCountsByMonth = keyBy(currentYearOrderCounts, _ => formatDate(_.month.toDate(), 'yyyyMM'));
  const prevYearOrderCountsByMonth = keyBy(prevYearOrderCounts, _ => formatDate(_.month.toDate(), 'yyyyMM'));
  const inventoryCountsByMonth = keyBy(inventoryCounts, _ => formatDate(_.month.toDate(), 'yyyyMM'));
  const productTypes = useCollectionSubscriptionInTenant(db.collection('productTypes').orderBy('index'));
  const productTypeOptions = productTypes.map(_ => ({ label: _.name, value: _.id }));
  const tmpOrders = useCollectionSubscriptionInTenant(db.collectionGroup('tmpOrders').where('status', '==', 'initial'));
  const tmpOrderItems = tmpOrders.flatMap(_ => _.orderItems);
  const tmpOrderItemCounts = mapValues(groupBy(tmpOrderItems, 'productId'), _ => sumBy(_, 'quantity'));
  const preOrders = useCollectionSubscriptionInTenant(db.collection('orders').where('status', '==', 'initial').where('isPreOrder', '==', true));
  const preOrderItems = preOrders.flatMap(_ => _.orderItems);
  const preOrderItemCounts = mapValues(groupBy(preOrderItems, 'productId'), _ => sumBy(_, 'quantity'));
  const rowGroups = productTypes.map((productType) => {
    const rows = bodyProducts.filter(_ => _.productTypeIds?.includes(productType.id)).map((product) => {
      const expectedOrderCountMultiple = currentProspectPattern?.[`expectedOrderCountMultiple__${product.id}`];
      const tmpOrderCountValue = tmpOrderItemCounts[product.id] || 0;
      const preOrderCountValue = preOrderItemCounts[product.id] || 0;
      const benchmarkProductIds = uniq([product.id, ...(product.benchmarkProductIds || [])]);
      const monthColumns = months.map((month, monthIndex) => {
        const isPast = month < startOfMonth(new Date());
        const isCurrentMonth = formatDate(month, 'yyyyMM') === formatDate(new Date(), 'yyyyMM');
        const currentYearOrderCount = isCurrentMonth ? (
          { values: { [product.id]: sumBy(currentMonthOrderItemsByProductId[product.id], 'quantity') } }
        ) : currentYearOrderCountsByMonth[formatDate(month, 'yyyyMM')];
        const prevYearOrderCount = prevYearOrderCountsByMonth[formatDate(addYears(month, -1), 'yyyyMM')];
        const prevYearOrderCountValue = sumBy(benchmarkProductIds, _ => prevYearOrderCount?.values[_] ?? 0);
        const vsPrevYearOrderCount = [currentYearOrderCount, prevYearOrderCount].some(_ => _ == null) ? null : (currentYearOrderCount.values[product.id] ?? 0) - prevYearOrderCountValue;
        const inventoryCount = inventoryCountsByMonth[formatDate(month, 'yyyyMM')];
        const manufactureCount = computedPurchaseAndContainerCounts[product.id]?.manufacture[formatDate(month, 'yyyyMM')];
        const arrivalCount = computedPurchaseAndContainerCounts[product.id]?.arrival[formatDate(month, 'yyyyMM')] || 0;
        const prevMonthColumn = _ => monthColumns[monthIndex - 1];
        const column = {
          key: [product.id, formatDate(month, 'yyyyMM')].join('__'),
          month,
          isPast,
          currentYearOrderCount: _ => (isPast || isCurrentMonth) ? (currentYearOrderCount ? (currentYearOrderCount.values[product.id] ?? 0) : null) : null,
          prevYearOrderCount: _ => prevYearOrderCount ? prevYearOrderCountValue : null,
          vsPrevYearOrderCount: _ => (isPast || isCurrentMonth) ? vsPrevYearOrderCount : null,
          vsPrevYearOrderCountRate: _ => column.vsPrevYearOrderCount() / column.prevYearOrderCount(),
          expectedOrderCount: _ => isPast ? null : Math.round(column.prevYearOrderCount() * (expectedOrderCountMultiple ?? 1.5)),
          tmpOrderCount: _ => isCurrentMonth ? tmpOrderCountValue : null,
          inventoryCount: _ => isPast ? (
            inventoryCount?.values[`inventory_${product.id}`]
          ) : prevMonthColumn() ? (prevMonthColumn().inventoryCount() - column.expectedOrderCount() + column.arrivalCount() - column.tmpOrderCount()) : null,
          shouldAlertInventory: _ => column.inventoryCount() < product.alertInventory,
          preOrderCount: _ => isPast ? null : isCurrentMonth ? preOrderCountValue : prevMonthColumn().preOrderCount() - Math.min(column.arrivalCount(), prevMonthColumn().preOrderCount()),
          manufactureCount: _ => manufactureCount,
          arrivalCount: _ => arrivalCount,
          transferingCount: _ => computedPurchaseAndContainerCounts[product.id]?.transferingCount(endOfMonth(month)),
          abroadInventoryCount: _ => computedPurchaseAndContainerCounts[product.id]?.abroadInventoryCount(endOfMonth(month)),
          shouldAlertAbroadInventory: _ => column.abroadInventoryCount() < 0 && endOfMonth(month) > addDays(new Date(), product.abroadInventoryLeadDays || 0),
        };
        return column;
      });
      return {
        ...product,
        expectedOrderCountMultiple,
        rowType: 'product',
        monthColumns,
      };
    });
    let filteredRows = rows;
    if (queryParams.showsHidden !== '1') {
      filteredRows = filteredRows.filter(_ => !_.isHidden);
    }
    const summaryRow = {
      rowType: 'productType',
      ...productType,
      monthColumns: months.map((month, monthIndex) => {
        const monthRows = filteredRows.map(_ => _.monthColumns[monthIndex]);
        const column = {
          key: [productType.id, formatDate(month, 'yyyyMM')].join('__'),
          month,
          ...fromPairs(
            [
              'currentYearOrderCount',
              'prevYearOrderCount',
              'vsPrevYearOrderCount',
              'expectedOrderCount',
              'tmpOrderCount',
              'inventoryCount',
              'preOrderCount',
              'manufactureCount',
              'arrivalCount',
              'transferingCount',
              'abroadInventoryCount',
            ].map((metric) => [
              metric,
              () => monthRows.every(_ => _[metric]() == null) ? null : sumBy(monthRows, r => r[metric]())
            ])
          ),
          shouldAlertInventory: _ => column.inventoryCount() < sumBy(rows, 'alertInventory'),
        };
        return column;
      }),
    };
    return {
      productType,
      rows,
      filteredRows,
      summaryRow,
    };
  });

  let filteredRowGroups = rowGroups.filter(_ => _.filteredRows.length > 0);
  if (!isEmpty(queryParams.productTypeIds)) {
    filteredRowGroups = filteredRowGroups.filter(_ => queryParams.productTypeIds.includes(_.productType.id));
  }

  const filteredRows = filteredRowGroups.flatMap(_ => _.filteredRows);

  const isSelecting = selectedIds.length > 0;
  const isAllSelected = isEqual(...[selectedIds, filteredRows.map(_ => _.id)].map(_ => sortBy(_, _ => _)));
  const onSubmitBatchEdit = async (values, { onClickClose, }) => {
    await batch(db, selectedIds, (b, _) => {
      b.update(db.collection('products').doc(_), {
        ...values,
        updatedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
      });
    });
    onClickClose();
  };
  useEffect(() => {
    if(queryParams.prospectPatternId == null && prospectPatterns.length > 0) {
      history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, prospectPatternId: prospectPatterns[0]?.id }));
    }
  }, [prospectPatterns]);

  return (
    <div>
      <div className="admin-inventories-schedule container-fluid py-5 position-relative bg-white">
        <InventoriesHeader activeTab="schedule" />
        <div className="mt-4 d-flex justify-content-start align-items-end gap-1 mb-1 position-relative" style={{ zIndex: 101 }}>
          <QuerySelector
            paramName='prospectPatternId'
            width={250}
            options={prospectPatternOptions}
            label='予想販売係数パターン'
            isClearable={false}
          />
          <ModalButton title="予想販売係数パターン" content={_ => <ProspectPatterns {...{ tenant, user, products: bodyProducts, }} />} modalProps={{ style: { minWidth: 700 } }}>
            予想販売係数パターン管理
          </ModalButton>
        </div>
        <div className="mt-4 d-flex justify-content-start align-items-end gap-1 mb-1 position-relative" style={{ zIndex: 100 }}>
          <QueryDateRangeSelector
            label="期間"
            defaultValue={[start, end]}
            paramName="dateRange"
            pickerProps={{ showMonthYearPicker: true, }}
          />
          <QuerySelector
            paramName='productTypeIds'
            width={250}
            isMulti
            options={productTypeOptions}
            label='商品種別で絞込み'
            disabled={isSelecting}
          />
          <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' disabled={isSelecting} />
          <QuerySelector
            paramName='metrics'
            width={600}
            isMulti
            options={metricOptions}
            defaultValue={defaultMetricNames}
            label='表示項目'
          />
        </div>
        {
          selectedIds.length > 0 && (
            <div className="d-flex mb-2 alert alert-info justify-content-between align-items-center">
              <div>{selectedIds.length}件を選択中</div>
              <ModalButton color="secondary" Modal={ModelFormModal} modalProps={{ title: '編集', fields: prospectFields({ products: bodyProducts, }), onSubmit: onSubmitBatchEdit, }}>
                編集
              </ModalButton>
            </div>
          )
        }
        <div className="overflow-auto" style={{ maxHeight: '100vh', zIndex: 0, }}>
          <table className="table table-bordered sticky-table m-0 bg-white">
            <thead className="bg-light-grey text-center">
              <tr>
                <th style={{ minWidth: 50 }} className="sticky bg-light-grey">
                  <Input
                    type='checkbox'
                    className='d-none position-relative m-0'
                    checked={isAllSelected}
                    onChange={_ => isAllSelected ? setSelectedId([]) : setSelectedId(filteredRows.map(_ => _.id))}
                  />
                </th>
                <th style={{ minWidth: 300, left: 50, }} className="sticky bg-light-grey">
                </th>
                <th style={{ minWidth: 130, left: 350, }} className="sticky bg-light-grey">
                  指標
                </th>
                {
                  months.map((month) => {
                    const isPast = month < startOfMonth(new Date());
                    const onClickSaveOrderCounts = async () => {
                      try {
                        await Promise.all([0, -1].map(_ => saveOrderCounts({ tenantId: tenant.id, monthString: addYears(month, _).toISOString(), })));
                      } catch(e) {
                        toast.error('失敗しました');
                      }
                    };

                    return (
                      <th key={month.toISOString()} style={{ minWidth: 130 }}>
                        {formatDate(month, 'yyyy/MM')}
                        <ProgressButton color="link" size="sm" className="p-1 text-primary" process={onClickSaveOrderCounts}>
                          <span className="fas fa-calculator" />
                        </ProgressButton>
                        {
                          isPast && (
                            <EditButton
                              color="link"
                              size="sm"
                              label={false}
                              className="p-1"
                              itemRef={db.collection('inventoryCounts').doc([tenant.id, formatDate(month, 'yyyyMM')].join('__'))}
                              FormModal={ModelFormModal}
                              formProps={{
                                title: '在庫数',
                                fields: fromPairs([
                                  ...filteredRows.map(_ => [`inventory_${_.id}`, { label: `在庫 ${_.id}`, type: 'integer', }]),
                                ]),
                              }}
                              beforeEdit={_ => _?.values}
                              beforeSubmit={_ => ({
                                tenantId: tenant.id,
                                month,
                                savedAt: new Date(),
                                values: _,
                              })}
                              type="set"
                            />
                          )
                        }
                      </th>
                    );
                  })
                }
              </tr>
            </thead>
            {
              filteredRowGroups.map((rowGroup) => {
                const { productType, } = rowGroup;
                return (
                  <RowGroup key={productType.id} {...{ ...rowGroup, tenant, user, bodyProducts, selectedIds, unselectId, selectId, cellNotesById, }} />
                );
              })
            }
          </table>
        </div>
      </div>
    </div>
  );
});


function RowGroup (props) {
  const { tenant, user, bodyProducts, productType, summaryRow, filteredRows, selectedIds, unselectId, selectId, cellNotesById, } = props;

  return (
    <tbody>
      {
        [summaryRow, ...filteredRows].map((row) => {
          const { id, ref, name, expectedOrderCountMultiple = 1, monthColumns, } = row;
          return (
            <Row key={id} {...{ tenant, user, row, selectedIds, unselectId, selectedIds, bodyProducts, cellNotesById, }} />
          );
        })
      }
    </tbody>
  );
}

function Row (props) {
  const { tenant, user, row, bodyProducts, selectedIds, unselectId, selectId, cellNotesById, } = props;
  const { id, ref, rowType, name, expectedOrderCountMultiple = 1, benchmarkProductIds, monthColumns, } = row;
  const queryParams = useQueryParams();
  const displayMetrics = queryParams.metrics ? keys(pickBy(metrics, (v, k) => console.log(k) || queryParams.metrics.includes(k))) : defaultMetricNames;

  return (
    <tr className={classnames({ 'font-weight-bold': rowType === 'productType' })}>
      {
        rowType === 'product' ? (
          <Fragment>
            <th style={{ minWidth: 50 }} className="sticky bg-light-grey">
              <Input
                type='checkbox'
                className='d-none position-relative m-0'
                checked={selectedIds.includes(id)}
                onChange={(_) => (selectedIds.includes(id) ? unselectId(id) : selectId(id))}
              />
            </th>
            <th style={{ minWidth: 300, left: 50, }} className="sticky bg-light-grey">
              <div>
                <div>
                  {id}
                </div>
                <div>
                  {name}
                </div>
                <div className="d-flex gap-1 mt-3">
                  <div className="d-flex gap-1 flex-wrap align-items-center">
                    <span className="badge badge-secondary">
                      {numeral(expectedOrderCountMultiple).format('0,0.0')}
                    </span>
                    <span className="badge badge-secondary">
                      {benchmarkProductIds?.join(', ')}
                    </span>
                  </div>
                  <EditButton
                    color="link"
                    size="sm"
                    label={false}
                    className="p-1"
                    itemRef={ref}
                    FormModal={ModelFormModal}
                    formProps={{ documentName: 'product', title: '商品編集', fields: prospectFields({ products: bodyProducts, }), }}
                  />
                </div>
              </div>
            </th>
          </Fragment>
        ) : (
          <th colSpan={2} style={{ minWidth: 350, }} className="sticky bg-light-grey">
            {name}
          </th>
        )
      }
      <th style={{ minWidth: 130, left: 350, }} className="sticky bg-light-grey">
        <div className="d-flex flex-column align-items-stretch">
          {
            entries(pick(metrics, displayMetrics)).map(([metric, { label }]) => {
              return (
                <div key={metric} className="text-right">
                  {label}
                </div>
              );
            })
          }
        </div>
      </th>
      {
        monthColumns.map((monthColumn, monthIndex) => {
          const { month, key, } = monthColumn;
          const cell = (n, isComparison, rate, to, className) => {
            const Component = to == null ? 'div' : TenantLink;
            return (
              <Component className={classnames('text-right d-flex justify-content-end align-items-center', className)} to={to}>
                {
                  n != null ? (
                    <Fragment>
                      <div>{(isComparison && n > 0 ? '+' : '') + numeral(n).format()}</div>
                      {rate != null && <div className="small">({isFinite(rate) ? numeral(rate).format('+0,0.0%') : ' '})</div>}
                    </Fragment>
                  ) : '-'
                }
              </Component>
            );
          };
          const hasNote = !isEmpty(cellNotesById[key]?.body);

          return (
            <td key={monthIndex} className="position-relative has-hovered-contents">
              <AdminHelp
                collectionName="cellNotes"
                helpKey={key}
                user={user}
                title="メモ"
                label={false}
                buttonProps={{
                  className: classnames('position-absolute p-0', { 'hovered-content': !hasNote }),
                  style: { top: 1, left: 2, },
                }}
                icon="sticky-note"
              />
              <div className="d-flex flex-column align-items-stretch">
                {
                  entries(pick(metrics, displayMetrics)).map(([metric, { isComparison = _ => false, rate, path, className, }]) => {
                    const value = monthColumn[metric]();
                    return cell(value, isComparison(), rate?.(monthColumn), path?.({ type: rowType, id, month, }), (className ? className(monthColumn) : value < 0 && 'text-danger'));
                  })
                }
              </div>
            </td>
          );
        })
      }
    </tr>
  );
}

function ProspectPatterns (props) {
  const { user, tenant, products, } = props;
  const prospectPatterns = sortBy(useCollectionSubscriptionInTenant(db.collection('prospectPatterns'), []), _ => _.createdAt.toDate());
  const prospectPatternFields = {
    name: {
      label: '名称',
      type: 'string',
      validations: { required: _ => !isEmpty(_), },
    },
    ...fromPairs(products.map((product) => {
      return [
        `expectedOrderCountMultiple__${product.id}`,
        {
          label: `予想販売係数 ${product.id}`,
          type: 'float',
          initialValue: 1,
        }
      ];
    })),
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <AddInTenantButton itemRef={db.collection('prospectPatterns').doc()} FormModal={ModelFormModal} formProps={{ documentName: 'prospectPattern', title: '予想販売係数パターン追加', fields: prospectPatternFields }} disabled={!canUpdateProspectPattern(user)} />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>名称</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            prospectPatterns.map((prospectPattern) => {
              const { id, ref, name, text, } = prospectPattern;
              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  <td className="text-nowrap text-right">
                    <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ documentName: 'prospectPattern', title: '予想販売係数パターン編集', fields: prospectPatternFields, }} disabled={!canUpdateProspectPattern(user)} />
                    <DeleteButton itemRef={ref} className="ml-1" disabled={!canUpdateProspectPattern(user)} />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}
