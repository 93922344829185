const { isHiragana } = require('wanakana');
const { sortBy, isEqual, countBy, upperFirst, mapKeys, pick, isNaN, orderBy, sumBy, get, isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');
const { format: formatDate, } = require('date-fns');

const { prefectures, } = require('../config');

const { entries } = Object;

module.exports = {
  fields: () => {
    return {
      shippedOn: {
        label: '出荷日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
      },
      trackingNumber: {
        label: '送り状番号',
        type: 'string',
      },
      destinationName: {
        label: '送り先名前',
        type: 'string',
      },
      destinationNameKana: {
        type: 'string',
        label: '送り先名前ふりがな',
        validations: {
          hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
        },
      },
      destinationPhone: {
        type: 'string',
        inputType: 'tel',
        label: '送り先電話番号',
        warnings: {
          cellPhone: v => (v || '').length < 3 || v.match(/^(070|080|090).*$/),
        },
      },
      destinationPostalCode: {
        type: 'string',
        label: '送り先郵便番号',
        validations: {
          format: v => (v || '').match(/^[0-9]{7}$/g),
        },
        inputProps: {
          type: 'tel',
        },
      },
      destinationPrefecture: {
        label: '送り先都道府県',
        type: 'select',
        options: entries(prefectures).map(([k, v]) => ({ value: k, label: v })),
      },
      destinationCity: {
        type: 'string',
        label: '送り先市区町村',
      },
      destinationAddress: {
        type: 'string',
        label: '送り先番地・建物名',
      },
      destinationEmail: {
        type: 'string',
        inputType: 'email',
        label: '送り先メールアドレス',
        validations: {
          format: v => !v || isEmail(v),
          notMultibyte: v => !v || !isMultibyte(v),
        },
        hint: `
          携帯電話の迷惑メール設定でパソコンメールを受信不可に設定されている方は、登録確認メールの受信のため「@vitamin-i.app」を受信設定してください。
          詳しくは各携帯電話会社にご確認下さい。
        `,
      },
    };
  },
  itemFields: ({ products, }) => {
    return {
      productId: {
        label: '商品',
        type: 'select',
        options: products.map(_ => ({ label: _.code, value: _.id })),
        validations: {
          required: v => !isEmpty(v),
        },
      },
      quantity: {
        label: '数量',
        type: 'integer',
        validations: {
          greaterThanOrEqualTo0: v => v != null && v >= 0,
        },
        showsTextInput: true,
      },
    };
  },
};
