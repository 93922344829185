const { pick, isEmpty } = require('lodash');

const fields = () => {
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: { required: (v) => !isEmpty(v) },
    },
    enName: {
      label: '名称(英語)',
      type: 'string',
    },
    path: {
      label: 'URLの固有文字列',
      type: 'string',
      validations: { required: (v) => !isEmpty(v) },
    },
    useSms: {
      label: 'SMSを使う',
      type: 'boolean',
    },
    useMobile: {
      label: 'モバイルアプリを使う',
      type: 'boolean',
    },
    useWms: {
      label: 'WMS機能を使う',
      type: 'boolean',
    },
  };
};

module.exports = {
  fields,
  generalSettingsFields: () => {
    return {
      ...pick(fields(), ['name', 'enName', 'useSms', 'useMobile', 'useWms']),
    };
  },
};
